<ng-container *ngIf="(accountContent | async)?.loaded">
  <article
    *ngIf="{ account: (accountContent | async)?.content } as accountContent"
    class="mat-typography"
  >
    <img
      [src]="'assets/exclamation.svg'"
      [alt]="''"
    />
    <h1 class="bh-heading bh-heading--1">Your session has expired.</h1>
    <div class="bh-button-single">
      <button class="bh-button--primary" (click)="onContinue()" type="button" [id]="'btn_Continue'">
        {{accountContent.account?.BHL_EmployerSearch_Button_Continue}}
      </button>
    </div>
    <bh-already-have-account
      [content]="accountContent.account"
      [showLogin]="false"
    ></bh-already-have-account>
  </article>
</ng-container>
