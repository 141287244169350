<ng-container *ngIf="(content | async)?.loaded || (loginContent | async)?.loaded">
  <div *ngIf="showMFaForm">
  <article
    class="mfa-account"
    *ngIf="{
      page: (content | async)?.content,
      account: (accountContent | async)?.content,
      login: (loginContent | async)?.content
    } as pageContent"
  >
    <h1 class="bh--heading">
      {{ !isMfaUserProfileBlank
        ? pageContent.page?.BHL_MFAContact_Label_Header
        : pageContent.login?.BHL_MFALogin_Label_Header }}
    </h1>
    <div class="container">
      <bh-stepper *ngIf="!isMfaUserProfileBlank" [currentStep]="3"></bh-stepper>
      <bh-employer-logo [heading]="clientName"></bh-employer-logo>
      <p class="field-content">
        {{ !isMfaUserProfileBlank
          ? pageContent.page?.BHL_MFAContact_Label_Content1
          : pageContent.login?.BHL_MFALogin_Label_Header2 }}
      </p>
      <form class="form" [formGroup]="mfaForm">
        <bh-sr-only-page-level-error
          [formGrp]="mfaForm"
        ></bh-sr-only-page-level-error>

        <div formArrayName="emailData" class="seperation">
          <div
            *ngFor="
              let field of emailDetails.controls;
              index as ind;
              first as isFirst;
              let last = last;
              trackBy: trackByContactFn
            "
            formGroupName="{{ ind }}"
          >
            <div class="form_field">
              <div class="radio_option">
                <input
                  type="radio"
                  name="radioOption"
                  [id]="ind + 'email'"
                  aria-label="email"
                  class="radio_field"
                  formControlName="radioOption"
                  (change)="onContactChange($event)"
                  value="email"
                />
              </div>
              <div
                class="d-flex form__field email"
                [class.has-errors]="
                  emailInvalid(ind) && (emailDirty(ind) || emailTouched(ind))
                "
              >
                <label [for]="'email' + ind" class="label-field">
                  {{ pageContent.page?.BHL_MFAContact_Label_Email
                  }}<span class="bh--marked" *ngIf="isFirst && !isMfaUserProfileBlank">*</span>
                </label>
                <input
                  [id]="'email' + ind"
                  type="email"
                  placeholder="{{
                    pageContent.page?.BHL_MFAContact_Label_EmailPlaceholder
                  }}"
                  formControlName="email"
                  [ngClass]="{
                    'input-field': true,
                     'input-field--read-only': emailIsReadOnly
                    }"
                  matInput
                  [attr.data-atm-input-email]="'Enter email' + ind"
                  [attr.aria-describedby]="
                    (userRequired(ind) &&
                    (emailDirty(ind) || emailTouched(ind) || submitted)
                      ? 'error-email-empty'
                      : '') + (userPattern(ind) ? 'error-valid-email-empty' : '')
                  "
                  [readonly]="emailIsReadOnly"
                />
                <div class="error-msg">
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      userRequired(ind) &&
                      (emailDirty(ind) || emailTouched(ind) || submitted)
                    "
                  >
                    <bh-field-level-error [signUpErrorGA4]="true" [formErrorGA4] = "signUpTitle"
                      [id]="'error-email-empty'"
                      [message]="pageContent.page?.BHL_MFAContact_Error_Email"
                    ></bh-field-level-error>
                  </div>
                  <div class="invalid-feedback" *ngIf="userPattern(ind)">
                    <bh-field-level-error [signUpErrorGA4]="true" [formErrorGA4] = "signUpTitle"
                      [id]="'error-valid-email-empty'"
                      [message]="
                        pageContent.page?.BHL_MFAContact_Error_InvalidEmail
                      "
                    ></bh-field-level-error>
                  </div>
                </div>
                <button
                  (click)="addContactRow('email')"
                  class="add_field"
                  type="button"
                  [id]="'addEmail' + ind"
                  *ngIf="(last && emailDetails.controls.length !== 3) && !isMfaUserProfileBlank"
                >
                  {{ pageContent.page?.BHL_MFAContact_Label_AddEmail }}
                </button>
              </div>
              <div *ngIf="(showRemoveIcon ? ind > 0 : ind >1)  && !isMfaUserProfileBlank" class="d-flex bh-minus-circle">
                <button
                  (click)="removeContactRow('email', ind)"
                  type="button"
                  class="remove_field"
                >
                  <img
                    [src]="'assets/logos/bh-minus-circle.svg'"
                    [alt]="pageContent.page?.BHL_MFAContact_Label_RemoveEmail"
                    class="minus-circle-wh"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="phoneData" class="margin_t phonedetails">
          <div
            *ngFor="
              let field of phoneDetails.controls;
              let ind = index;
              first as isFirst;
              let last = last;
              trackBy: trackByContactFn
            "
            [formGroupName]="ind"
          >
            <div class="form_field">
              <div class="radio_option">
                <input
                  type="radio"
                  [id]="ind + 'mobile'"
                  name="radioOption"
                  aria-label="phone"
                  class="radio_field"
                  formControlName="radioOption"
                  (change)="onContactChange($event)"
                  value="mobile"
                />
              </div>
              <div *ngIf="!isMfaUserProfileBlank"
                class="d-flex form__field select_field"
                [class.has-errors]="
                  countryInvalid(ind) &&
                  (countryDirty(ind) || countryTouched(ind))
                "
              >
                <label [for]="'country' + ind" class="label-field margin__0">
                  {{ pageContent.page?.BHL_MFAContact_Label_CTRY
                  }}<span class="bh--marked" *ngIf="isFirst">*</span>
                </label>
                <select
                  [id]="'country' + ind"
                  data-atm-select-country="Country"
                  name="country"
                  class="country select-field"
                  formControlName="country"
                  [attr.aria-describedby]="
                    countryRequired(ind) &&
                    (countryTouched(ind) || countryDirty(ind) || submitted)
                      ? 'error-country-empty'
                      : ''
                  "
                >
                  <option value="" selected disabled>Select</option>
                  <option
                    *ngFor="
                      let country of countries | async;
                      trackBy: trackByFn
                    "
                    [value]="country.objName"
                  >
                    {{ country.objId }}
                  </option>
                </select>
                <div class="error-msg">
                  <div
                    class="invalid-feedback margin__country"
                    *ngIf="
                      countryRequired(ind) &&
                      (countryTouched(ind) || countryDirty(ind) || submitted)
                    "
                  >
                    <bh-field-level-error [signUpErrorGA4]="true" [formErrorGA4] = "signUpTitle"
                      [id]="'error-country-empty'"
                      [message]="
                        pageContent.page?.BHL_MFAContact_Error_MissCTRY
                      "
                    ></bh-field-level-error>
                  </div>
                </div>
              </div>
              <div
                class="d-flex form__field"
                [class.has-errors]="
                  phoneInvalid(ind) && (phoneDirty(ind) || phoneTouched(ind))
                "
              >
                <label
                  [for]="'phoneNumber' + ind"
                  class="label-field margin__0"
                >
                  {{ pageContent.page?.BHL_MFAContact_Label_Mobile
                  }}<span class="bh--marked" *ngIf="isFirst && !isMfaUserProfileBlank">*</span>
                </label>
                <input
                  [id]="'phoneNumber' + ind"
                  type="text"
                  placeholder="{{
                    pageContent.page?.BHL_MFAContact_Label_NumberPlaceholder
                  }}"
                  formControlName="phoneNumber"
                  [ngClass]="{
                  'input-field': true,
                     'email-id': emailIsReadOnly
                    }"
                  matInput
                  [attr.data-atm-input-phoneNumber]="'Enter your number' + ind"
                  inputmode="numeric"
                  bh-numbers-only
                  [attr.aria-describedby]="
                    (phoneRequired(ind) &&
                    (phoneTouched(ind) || phoneDirty(ind) || submitted)
                      ? 'error-mobile-number-empty'
                      : '') + (minLengthValid(ind) && phoneNoValue(ind) ? 'error-number-limit-empty' : '')
                  "
                  [readonly]="emailIsReadOnly"
                />
                <div class="error-msg">
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      phoneRequired(ind) &&
                      (phoneTouched(ind) || phoneDirty(ind) || submitted)
                    "
                  >
                    <bh-field-level-error [signUpErrorGA4]="true" [formErrorGA4] = "signUpTitle"
                      [id]="'error-mobile-number-empty'"
                      [message]="
                        pageContent.page?.BHL_MFAContact_Error_MissPhone
                      "
                    ></bh-field-level-error>
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="minLengthValid(ind) && phoneNoValue(ind)"
                  >
                    <bh-field-level-error [signUpErrorGA4]="true" [formErrorGA4] = "signUpTitle"
                      [id]="'error-number-limit-empty'"
                      [message]="
                        pageContent.page?.BHL_MFAContact_Error_PhoneCount
                      "
                    ></bh-field-level-error>
                  </div>
                </div>
              </div>
              <div *ngIf="ind > 0 && !isMfaUserProfileBlank" class="d-flex bh-minus-circle">
                <button
                  (click)="removeContactRow('mobile', ind)"
                  class="remove_field"
                  type="button"
                >
                  <img
                    [src]="'assets/logos/bh-minus-circle.svg'"
                    [alt]="pageContent.page?.BHL_MFAContact_Label_RemoveNumber"
                    class="minus-circle-wh"
                  />
                </button>
              </div>
            </div>
            <div *ngIf="!isMfaUserProfileBlank">
              <button
                *ngIf="last && phoneDetails.controls.length !== 3"
                class="add_mobile_field"
                (click)="addContactRow('mobile')"
                type="button"
              >
                {{ pageContent.page?.BHL_MFAContact_Label_AddNumber }}
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!isMfaUserProfileBlank" class="bh-button-single">
          <button class="bh-button--primary" type="submit" (click)="onSend()">
            Send
          </button>

          <div *ngIf="isSkipNowEnabled" class="retry-passcode">        
            <button
              class="bh-button--inline-link"
              type="button" 
              (click)="SkipNowMfaOtp()"          
            >
              {{ pageContent.page?.BHL_MFAContact_Label_SkipNow }}
            </button>
          </div>  

        </div>

        <div *ngIf="isMfaUserProfileBlank" class="bh-button-pair">
          <button
            id="btnPrevious"
            class="bh-button--secondary"
            data-atm-button="previous"
            type="button"
            (click)="onPrevious()"
          >
            {{ pageContent.account?.BHL_EmployerSearch_Button_Previous }}
          </button>
          <button
            class="bh-button--primary"
            type="submit"
            data-atm-button="continue"
            (click)="onContinue()"
            [id]="'btn_Continue'"
          >
            {{ pageContent.account?.BHL_EmployerSearch_Button_Continue }}
          </button>         
        </div>   
           
      </form>
    </div>
    <bh-already-have-account
      [content]="(accountContent | async)?.content"
      [showLogin]="false"
    ></bh-already-have-account>
  </article></div>
</ng-container>
