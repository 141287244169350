<ng-container
  *ngIf="(usernameContent | async)?.loaded || (passwordContent | async)?.loaded"
>
   <div *ngIf="showForgotUNConfirmation">
    <article class="username-confirmation" 
    *ngIf="{
      page: (usernameContent | async)?.content,
      passwordPage: (passwordContent | async)?.content,
      account: (accountContent | async)?.content
     } as pageContent">
        <div class="success-icon">
            <img [src]="'assets/support.svg'" [alt]="'Success'" />
        </div>
        <div class="sr-only" role="alert">{{pageContent.page?.BHL_ForgotUNConfirm_NewHeader}} {{pageContent.page?.BHL_ForgotUNConfirm_NewHeader2}}</div>
        <h1 class="bh--heading" aria-hidden="true">
            {{pageContent.page?.BHL_ForgotUNConfirm_NewHeader}}
        </h1>
        <p class="p-username"><b>{{this.userName}}</b></p>
        <p class="explore-message" aria-hidden="true">
            <span>{{pageContent.page?.BHL_ForgotUNConfirm_NewHeader2}}</span>
        </p>
        <div class="bh-button-single c-login">
            <button
              type="submit"
              class="bh-button--primary"
              data-atm-button="login"
              (click)="onLogin()"
              [id]="'btn_Login'"
            >
              Log in
            </button>
          </div>
        <div>
            <bh-already-have-account
              [showJoinToday]="true"
              [showLogin]="false"
              [content]="(accountContent | async)?.content"
            ></bh-already-have-account>
        </div>
    </article>
</div>
<div *ngIf="showResetPWConfirmation">
    <article class="password-confirmation" 
    *ngIf="{
      page: (usernameContent | async)?.content,
      passwordPage: (passwordContent | async)?.content,
      account: (accountContent | async)?.content
     } as pageContent">
        <div class="success-icon">
            <img [src]="'assets/success-icon.svg'" [alt]="'Success'" />
        </div>
        <div class="sr-only" role="alert">{{pageContent.passwordPage?.BHL_ResetPWConfirm_NewHeader}} {{pageContent.passwordPage?.BHL_ResetPWConfirm_NewHeader2}}</div>
        <h1 class="bh--heading" aria-hidden="true">
           {{pageContent.passwordPage?.BHL_ResetPWConfirm_NewHeader}}
        </h1>
        <p class="explore-message" aria-hidden="true">
            <span>{{pageContent.passwordPage?.BHL_ResetPWConfirm_NewHeader2}}</span>
        </p>
        <div class="bh-button-single">
            <button
              type="submit"
              class="bh-button--primary"
              data-atm-button="login"
              (click)="onLogin()"
              [id]="'btn_Login'"
            >
              Log in
            </button>
          </div>
        <div>
            <bh-already-have-account
              [showJoinToday]="true"
              [showLogin]="false"
              [content]="(accountContent | async)?.content"
            ></bh-already-have-account>
        </div>
    </article>
</div>
</ng-container>
