export enum LocalStorageKey {
  benefitId = 'bh-benefitId',
  clientGuid = 'bh-clientGuid',
  userInfoId = 'bh-userInfoId',
  isnonsso = 'bh-nonsso',
  fstargetid = 'bh-fstargetid',
  isresentotp = 'bh-resentotp',
  email = 'bh-email',
  isAltEmailDuplicate = 'bh-alternateEmailDuplicate',
  isOTPEnabled = 'bh-otpenabled',
  restoreUserInfo = 'bh-restoreUserInfo',
  uname = 'bhuname',
  isUserNavigate = 'bh-usernavigate',
  isForgotUnFlow = 'bh-isForgotUnFlow',
  isForgotPwFlow = 'bh-isForgotPwFlow',
  isLoginNewRedirectStop = 'bh-loginNewRedirectStop',
  isOsrSSO = 'bh-isOsrSSO',
  isLoginFlow = 'bh-isLoginFlow',
  benefitNotSupported = 'bh-benefitNotSupported',
  userNavigationState = 'bh-userNavigationState',
  verifyotpstate = 'bh-verifyotpstate',
  isNewPwLoginFlow = 'bh-isNewPwLoginFlow',
  requestId = 'bh-requestId',
  isPageRefresh = 'bh-isPageRefresh',
  loginusername = 'bh-loginusername',
  magicLink = 'bh-magicLink',
  tempMagicLink = 'bh-tempMagicLink',
  otpLink = 'bh-otpLink',
  clientBenefits = 'bh-clientBenefits',
  isPreviousPageBenefitSelection = 'bh-isPreviousPageBenefitSelection',
  isBH = 'bh-isbh',
}
